<template>
    <GenericPage
        :headerProps="{
            title: $t('cp__share__company_page'),
            subtitle: $t('cp__share__company_page__subtitle'),
            disableBackButton: true,
        }"
        :loading="isLoading"
    >
        <SettingsForm v-if="!isLoading" class="settings_form">
            <SettingsBlock class="settings_block" :title="$t('cp__share__company_page__details')">
                <SettingsSection class="settings_fieldset" label="" maxWidth="650px">
                    <label class="subtitle2">{{
                        $t("cp__share__company_page__details___body")
                    }}</label>
                    <div class="company_button__div">
                        <button type="button" class="company_button" @click="openCompanyLink">
                            <font-awesome-icon
                                class="company_button__icon"
                                :icon="['far', 'external-link-alt']"
                            />
                            <span class="company_button__name">{{
                                $t("cp__share__company_page__details___your_company_page")
                            }}</span>
                        </button>
                    </div>

                    <label
                        class="subtitle3"
                        :class="{ 'subtitle3--small-margin': companyPageSettings.logo !== null }"
                    >
                        {{ $t("cp__share__company_page__details___logo") }}
                    </label>
                    <template v-if="companyPageSettings.logo">
                        <label class="subtitle2">{{
                            $t("cp__share__company_page__details___logo_current")
                        }}</label>
                        <div class="current_logo_container">
                            <img
                                class="current_logo"
                                :src="companyPageSettings.logo"
                                :alt="$t('cp__share__company_page__details___logo')"
                            />
                        </div>
                        <label class="subtitle2">{{
                            $t("cp__share__company_page__details___logo_choose_new")
                        }}</label>
                    </template>
                    <div class="settings__extra_input-wrapper">
                        <FileDragDrop
                            ref="fileDragDrop"
                            v-model="companyPageSettings.newlogo"
                            :acceptMultiple="false"
                            :showActionBtns="false"
                            type="image"
                            :validationErrors="[$v.companyPageSettings.newlogo.$error]"
                            :validationErrorMessages="[
                                resolveErrorMessage($v.companyPageSettings.newlogo),
                            ]"
                        />
                    </div>

                    <div class="settings__extra_input-wrapper">
                        <label class="subtitle3">{{
                            $t("cp__share__company_page__details__company_details")
                        }}</label>
                        <TextInput
                            :value="companyPageSettings.name"
                            :label="$t('cp__share__company_page__details___name')"
                            disabled
                        />
                        <TextInput
                            v-model="companyPageSettings.description"
                            :label="$t('cp__share__company_page__details___description')"
                            :multiline="true"
                            class="company_settings__description"
                            enableExternalValidation
                            displayValueMaxLength
                            :valueMaxLength="1000"
                            :externalValidationError="$v.companyPageSettings.description.$error"
                            :externalValidationErrorMessage="
                                resolveErrorMessage($v.companyPageSettings.description)
                            "
                        />
                    </div>
                </SettingsSection>
            </SettingsBlock>
            <SettingsBlock
                v-if="companyPageSettings.locationManagement"
                class="settings_block"
                :title="$t('cp__share__company_page__locationa_management')"
            >
                <SettingsSection class="settings_fieldset" label="">
                    <label class="subtitle2">{{
                        $t("cp__share__company_page__locationa_management_body")
                    }}</label>
                    <div>
                        <button type="button" class="location_button" @click="openLocation">
                            <span>{{ $t("cp__share__company_page__location__button") }}</span>
                        </button>
                    </div>
                </SettingsSection>
            </SettingsBlock>
            <SettingsBlock
                class="settings_block"
                :title="$t('cp__share__company_page__contact_information')"
            >
                <SettingsSection
                    class="settings_fieldset"
                    :label="$t('cp__share__company_page__contact_information_subtitle')"
                >
                    <label class="subtitle2">{{
                        $t("cp__share__company_page__contact_information_body")
                    }}</label>

                    <label class="subtitle3">{{
                        $t("cp__share__company_page__contact_information_location")
                    }}</label>
                    <TextInput
                        v-model="companyPageSettings.address.street"
                        :label="$t('cp__share__company_page__contact_information_address')"
                    />
                    <TextInput
                        v-model="companyPageSettings.address.zipCode"
                        :label="$t('cp__share__company_page__contact_information_zip_code')"
                    />
                    <TextInput
                        v-model="companyPageSettings.address.city"
                        :label="$t('cp__share__company_page__contact_information_city')"
                    />
                    <TextInput
                        v-model="companyPageSettings.country"
                        disabled
                        :label="$t('cp__share__company_page__contact_information_country')"
                    />

                    <label class="subtitle3">{{
                        $t("cp__share__company_page__contact_information")
                    }}</label>

                    <label class="subtitle2">{{
                        $t("cp__share__company_page__contact_information_info")
                    }}</label>

                    <TextInput
                        v-model="companyPageSettings.email"
                        :label="$t('cp__share__company_page__contact_information_email')"
                        enableExternalValidation
                        :externalValidationError="$v.companyPageSettings.email.$error"
                        :externalValidationErrorMessage="
                            resolveErrorMessage($v.companyPageSettings.email)
                        "
                    />
                    <TextInput
                        v-model="companyPageSettings.phoneNumber"
                        :label="$t('cp__share__company_page__contact_information_phone')"
                    />

                    <label class="subtitle3">{{
                        $t("cp__share__company_page__contact_information__social_media")
                    }}</label>
                    <TextInput
                        v-model="companyPageSettings.facebookUrl"
                        :label="$t('cp__share__company_page__contact_information_facebook')"
                        enableExternalValidation
                        :externalValidationError="$v.companyPageSettings.facebookUrl.$error"
                        :externalValidationErrorMessage="
                            resolveErrorMessage($v.companyPageSettings.facebookUrl)
                        "
                    />
                </SettingsSection>
            </SettingsBlock>
            <SettingsBlock
                class="settings_block"
                :title="$t('cp__share__company_page__corporate_information')"
            >
                <SettingsSection
                    class="settings_fieldset"
                    :label="$t('cp__share__company_page__corporate_information_subtitle')"
                >
                    <label class="subtitle2">{{
                        $t("cp__share__company_page__corporate_information_body")
                    }}</label>
                    <TextInput
                        v-model="companyPageSettings.chamberOfCommerceNumber"
                        :label="
                            $t(
                                'cp__share__company_page__corporate_information__chamber_of_commerce'
                            )
                        "
                    />
                </SettingsSection>
            </SettingsBlock>
            <SettingsBlock
                class="settings_block"
                :title="$t('cp__share__company_page__quality_mark')"
            >
                <SettingsSection
                    class="settings_quality_mark_fieldset"
                    :label="$t('cp__share__company_page__quality_mark')"
                    maxWidth="650px"
                >
                    <label class="subtitle2">{{
                        $t("cp__share__company_page__quality_marks_body")
                    }}</label>
                    <div
                        class="quality_marks"
                        v-for="(mark, index) in companyPageSettings.qualityMarks"
                        :key="mark.name"
                    >
                        <QualityMark
                            :mark="mark"
                            :urlError="$v.companyPageSettings.qualityMarks.$each[index].$error"
                            :urlErrorMessage="
                                resolveErrorMessage(
                                    $v.companyPageSettings.qualityMarks.$each[index].link
                                )
                            "
                            :placeholder="
                                mark.code === 'opiness'
                                    ? $t(
                                          'cp__share__company_page__quality_mark_opiness_placeholder'
                                      )
                                    : 'https://example.com'
                            "
                        >
                        </QualityMark>
                    </div>
                </SettingsSection>
            </SettingsBlock>
        </SettingsForm>
        <SettingsFooter>
            <Button @click.native="saveSettings" :loading="isSaving" :disabled="isDirty">
                {{ $t("portal instellingen (button)") }}
            </Button>
        </SettingsFooter>
    </GenericPage>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/pro-regular-svg-icons";
import { mapGetters } from "vuex";
import deepEqual from "deep-equal";
import cloneDeep from "lodash.clonedeep";
import QualityMark from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/QualityMark.vue";
import FileDragDrop from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/FileDragDrop.vue";
import TextInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/TextInput.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import * as Sentry from "@sentry/vue";
import GenericPage from "@/components/Global/Molecules/GenericPage.vue";
import { resolveErrorMessage, validationMixin, scrollToFirstError } from "@/validation";
import { getCompanyPageSettingsSchema } from "@/validation/schemas/companyPageSettings";
import SettingsForm from "@/components/Settings/Molecules/SettingsForm.vue";
import SettingsFooter from "@/components/Settings/Molecules/SettingsFooter.vue";
import SettingsBlock from "../../components/Settings/Molecules/SettingsBlock.vue";
import SettingsSection from "../../components/Settings/Molecules/SettingsSection.vue";
import getCompanyPageSettings from "../../graphql/settings/getCompanyPageSettings.gql";
import updateCompanyPageSettings from "../../graphql/settings/updateCompanyPageSettings.gql";

library.add(faExternalLinkAlt);

export default {
    name: "CompanySettingsPage",
    mixins: [validationMixin],
    components: {
        GenericPage,
        Button,
        FileDragDrop,
        TextInput,
        SettingsForm,
        SettingsFooter,
        SettingsBlock,
        SettingsSection,
        QualityMark,
        FontAwesomeIcon,
    },
    validations() {
        return getCompanyPageSettingsSchema(this.companyPageSettings);
    },
    computed: {
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
        }),
        isDirty() {
            return deepEqual(this.companyPageSettings, this.initialCompanyPageSettings);
        },
    },
    data() {
        return {
            isLoading: false,
            isSaving: false,
            initialCompanyPageSettings: null,
            companyPageSettings: {
                name: null,
                logo: null,
                newlogo: null,
                description: null,
                address: {
                    street: null,
                    zipCode: null,
                    city: null,
                },
                country: null,
                email: null,
                phoneNumber: null,
                facebookUrl: null,
                chamberOfCommerceNumber: null,
                qualityMarks: [],
            },
        };
    },
    methods: {
        resolveErrorMessage,
        scrollToFirstError,
        async getSettings() {
            this.isLoading = true;
            const { data } = await this.$apollo.query({
                query: getCompanyPageSettings,
                variables: { customerName: this.activeAccount.slug },
                fetchPolicy: "network-only",
            });

            /* remove when we get final decision regarding quality marks */
            data.companyPageSettings.qualityMarks.forEach((qualityMark, index) => {
                if (qualityMark.name === "springest") {
                    data.companyPageSettings.qualityMarks.splice(index, 1);
                }
            });

            this.mapResultsToState(data.companyPageSettings);
            this.isLoading = false;
        },
        waitForTouch() {
            return new Promise((resolve) => {
                this.unwatch = this.$watch(
                    () => !this.$v.$pending,
                    (isNotPending) => {
                        if (isNotPending) {
                            resolve();
                        }
                    },
                    { immediate: true }
                );
            });
        },
        async saveSettings() {
            this.isSaving = true;
            this.$v.$touch();
            await this.waitForTouch();
            this.unwatch();
            if (this.$v.$error) {
                await this.triggerFormErrorNotification();
                this.$nextTick(() => {
                    this.scrollToFirstError(this.$el);
                });
                this.isSaving = false;
                return;
            }

            try {
                // Perform the mutation
                const { data } = await this.$apollo.mutate({
                    mutation: updateCompanyPageSettings,
                    variables: {
                        customerName: this.activeAccount.slug,
                        logo: this.companyPageSettings.newlogo,
                        input: {
                            name: this.companyPageSettings.name,
                            description: this.emptyStringIfNull(
                                this.companyPageSettings.description
                            ),
                            address: {
                                street: this.emptyStringIfNull(
                                    this.companyPageSettings.address.street
                                ),
                                zipCode: this.emptyStringIfNull(
                                    this.companyPageSettings.address.zipCode
                                ),
                                city: this.emptyStringIfNull(this.companyPageSettings.address.city),
                            },
                            email: this.companyPageSettings.email,
                            phoneNumber: this.emptyStringIfNull(
                                this.companyPageSettings.phoneNumber
                            ),
                            facebookUrl: this.emptyStringIfNull(
                                this.companyPageSettings.facebookUrl
                            ),
                            // @todo: [TT-791] remove as soon as this is cleaned up in gql
                            vatNumber: "",
                            chamberOfCommerceNumber: this.emptyStringIfNull(
                                this.companyPageSettings.chamberOfCommerceNumber
                            ),
                            qualityMarks: this.mapQualityMarks(
                                this.companyPageSettings.qualityMarks
                            ),
                        },
                    },
                });

                this.mapResultsToState(data.updateCompanyPageSettings);
                this.$v.$reset();

                await this.$store.dispatch("pushNotification", {
                    type: "success",
                    title: this.$t("cp__share__company_page__success__title"),
                    message: this.$t("cp__share__company_page__success__message"),
                });

                this.$store.commit("AccountStore/updateAccountDataByKey", [
                    { key: "logo", value: this.companyPageSettings.logo },
                ]);

                this.$refs.fileDragDrop.clear();
            } catch (error) {
                Sentry.captureException(error);
                await this.triggerFormErrorNotification();
            }

            this.isSaving = false;
        },
        mapResultsToState(settings) {
            settings.newlogo = null;
            this.companyPageSettings = cloneDeep(settings);
            this.mapQualityMarksForDisplay(this.companyPageSettings.qualityMarks);
            this.initialCompanyPageSettings = cloneDeep(this.companyPageSettings);
        },
        mapQualityMarksForDisplay(qualityMarks) {
            qualityMarks.forEach((mark) => {
                mark.code = mark.name;
                mark.name = this.$t(
                    `cp__share__company_page__quality_mark_${mark.name}`,
                    mark.name
                );
            });
        },
        mapQualityMarks(inputQualityMarks) {
            const qualityMarks = [];
            inputQualityMarks.forEach((mark) => {
                qualityMarks.push({
                    name: mark.code,
                    link: this.emptyStringIfNull(mark.link),
                    enabled: mark.enabled,
                });
            });
            return qualityMarks;
        },
        async triggerFormErrorNotification() {
            await this.$store.dispatch("pushNotification", {
                type: "error",
                title: this.$t("cp__share__company_page__error__title"),
                message: this.$t("cp__share__company_page__error__message"),
            });
        },
        openCompanyLink() {
            window.open(this.companyPageSettings.companyPageUrl);
        },
        openLocation() {
            this.$router.push({
                path: "../settlements/?campagnebeheer_NL&settingtype=vestigingen",
            });
        },
        emptyStringIfNull(value) {
            return value || "";
        },
    },
    async mounted() {
        await this.getSettings();
    },
};
</script>

<style lang="scss" scoped>
@import "../../style_variables/style_variables.scss";
@import "~include-media";

.settings_form {
    width: 100%;
    max-width: 950px;
}

.settings_block {
    width: 100%;
    margin-top: 40px;

    &:first-of-type {
        margin-top: 40px;
    }

    &:last-of-type {
        margin-bottom: 170px;
    }
}

.settings_block::v-deep .headline6 {
    letter-spacing: 0px;
    font-size: 18px;
}

.settings_fieldset * {
    margin-bottom: 12px;
}

label {
    display: block;
}

.settings_quality_mark_fieldset * {
    margin-bottom: 16px;
}

.settings_quality_mark_fieldset::v-deep .subtitle1 * {
    font-weight: 600;
    letter-spacing: 0px;
}

.settings_fieldset::v-deep .subtitle1 * {
    font-weight: 600;
    letter-spacing: 0px;
}

.settings__extra_input-wrapper {
    max-width: 400px;
}

.quality_marks {
    margin-top: 40px;

    &:first-of-type {
        margin-top: 30px;
    }
}

.company_button__div {
    display: block;
}

.company_button {
    @extend %button_typography_style;
    background-color: #ffffff;
    border: 1px solid #c7c7cc;
    border-radius: 4px;
    height: 48px;
    width: 234px;
    margin-bottom: 0px;

    &:hover {
        cursor: pointer;
        background: $grey_bg;
        color: $grey_shark;
    }
}

.company_button__name {
    margin-bottom: 0px;
}

.company_button__icon {
    margin-bottom: 0px;
    margin-right: 10px;
}

.location_button {
    @extend .company_button;
    width: 135px;
}

.company_settings__description::v-deep .text_input {
    max-height: 200px;
}

.subtitle3--small-margin {
    margin-bottom: 4px;
}

.subtitle3 {
    margin-top: 30px;
}

.current_logo_container {
    height: 110px;
    width: 110px;
    border: 1px solid $grey_mischka;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;

    .current_logo {
        margin: 0;
        width: 100%;
    }
}
</style>
